import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import ContentContainer from "../components/contentContainer"
import FluidContainer from "../components/fluidContainer"
import { Header1 } from "../components/headings"
import ReactMarkdown from "react-markdown"
import SEO from "../components/seo"

const InfoContainer = styled.div`
  background-color: #eaecec;
  padding: 2rem;
  font-family: roboto;
  font-size: 16px;
  font-weight: 300;
  color: black;
`
const Host = ({ data }) => (
  <Layout>
    <SEO title="Host" keywords={[`eating`, `contests`, `official`]} />
    <FluidContainer>
      <ContentContainer>
        <InfoContainer>
          <Header1>{data.strapiPages.title}</Header1>
          <ReactMarkdown
            escapeHtml={false}
            source={data.strapiPages.mainCopy}
          />
        </InfoContainer>
      </ContentContainer>
    </FluidContainer>
  </Layout>
)

export default Host

export const pageQuery = graphql`
  query HostQuery {
    strapiPages(selector: { eq: "host" }) {
      mainCopy
      title
    }
  }
`
